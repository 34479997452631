
@font-face {
  font-family: "Circular";
  src: url('./Fonts/CircularCustCapNum-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url('./Fonts/CircularCustCapNum-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url('./Fonts/CircularCustCapNum-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url('./Fonts/CircularCustCapNum-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url('./Fonts/CircularCustCapNum-Light.otf');
  font-weight: 300;
  font-style: normal;
}




html, body {
  max-width: 100%;
  font-family:  "Circular", Futura, sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background: #14121d;
  scroll-behavior: smooth;
}

.Course {
  max-width: 400px;
  margin: auto;
}

.Course-content {
  margin: 16px;
}

.Course-image {
  border-radius: 6px;
  margin: 4px 0 8px;
  max-width: 100%;
}

.Course-info {
  display: flex;
  margin: 24px 0 12px;
}

.Course-info-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Course-number {
  color: #8A97FF;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.CourseHeading {
  width: 100%;
  margin: 0;
  font-weight: 900;
}

.CourseLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.CourseLevelTitle {
  display: flex;
  margin: 40px 0 28px;
}

.CourseLevelProgress {
  width: 160px;
  height: 4px;
  background-color: #4e4d65;
  border-radius: 2px;
  margin-top: 8px;
}

.CourseLevelProgressBar {
  width: 8px;
  height: 4px;
  border-radius: 2px;
}

.CourseLevelTopics {
  width: 100%;
}

.CourseLevelImage {
  display: flex;
  margin-right: 16px;
}

.CourseLevelImageColumn {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.CourseLevelImageBlock {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: #4E4D65;
  margin: 2px 3px 2px 1px;
}

.CourseLevelTopicCardContainer:hover {
  cursor: pointer;
}

.CourseLevelTopicCard {
  background-color: #252435;
  border-radius: 8px;
  margin: 16px 8px 16px 44px;
  display: flex;
  flex-shrink: 0;
}

.CourseLevelTopicCardImage {
  position: absolute;
  margin-top: 12px;
  max-width: 117px;
}

.CourseLevelTopicCardContent {
  margin: 20px 20px 20px 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CourseLevelTopicCardTitle {
  font-weight: 700;
  margin-top: 4px;
  font-size: 20px;
}




.CourseLevelTopicStatus {
  box-shadow: -9px 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: rotate(-90deg);
}

.CourseLevelTopicStatus:hover {
  cursor: pointer;
}

.CourseLevelTopicStatus, 
.CourseLevelTopicStatusRing, 
.StatusRing-bg,
.StatusRing {
  width: 54px;
  height: 54px;
}

.StatusRing-bg,
.StatusRing {
  position: absolute;
  fill: none;
  stroke-width: 4;
  transform: translate(3px, 3px);
  stroke-linecap: round;
}

.StatusRing-bg {
  stroke-dashoffset: 0;
  stroke: #4E4D65;
}

.StatusRing {
  stroke-dasharray: 151;
  stroke-dashoffset: calc(151 - (151 * 50) / 100);
  stroke: #5568fe;
  transition: .2s ease-out;
}

.CourseLevelTopicStatusButton {
  background: #5568fe;
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 48px;
  left: 10px;

}

.CourseLevelTopicStatusButton-icon {
  width: 14px;
  margin-top: 2px;
  transform: rotate(90deg);
}




.CourseLevelTopicStatus-lock {
  margin-left: 4px;
}

.TopicOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #252435;
  box-shadow: 0px 8px 8px rgba(4, 3, 16, 0.15);
  border-radius: 8px;
  margin-top: 50px;
  font-size: 14px;
}

.TopicOverview-image {
  width: 45%;
  max-width: 250px;
  margin: -50px auto -40px;
}

.TopicOverview-content {
  margin: 16px;
}

.TopicOverview-type {
  font-size: 16px;
  color: #B7B9CB;
  margin: 0 0 4px;
}

.TopicOverview-heading {
  font-size: 28px;
  margin: 0;
}

.TopicOverview-description {
  max-width: 90%;
  line-height: 1.5;
  opacity: .8;
  font-size: 16px;
  margin: 12px auto 24px;
}

.TopicOverview-lessons {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 30px auto 16px;
}

.TopicOverview-lessons-line {
  background: #46455B;
  height: 6px; 
  width: 62%;
  max-width: 255px;
  margin: 12px 0 0 16px;
  position: absolute;
}

.TopicOverview-lessons-line-fill {
  height: 6px;
  background: #5568FE;
  border-radius: 3px;
}

.TopicOverviewLesson {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopicOverviewLesson-dot {
  background: #46455B;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-bottom: 16px;
  z-index: 10;
}

.TopicOverviewLesson-dot-unlocked {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #5568FE;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.5);
  z-index: 10;
  margin: -9px 0 7px;
  display: flex;
  justify-content: center;
  animation: pulsate 1s alternate ease-in-out infinite;
  transform: scale(1);
}

.TopicOverviewLesson-dot-done {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #5568FE;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
  margin: -4px 0 12px;
  display: flex;
  justify-content: center;
}

.TopicOverviewLesson-dot-unlocked:hover {
  cursor: pointer
}

.TopicOverviewLesson-dot-image {
  margin-left: 3px;
  animation: pulsate 1s alternate ease-in-out infinite;
  transform: scale(1);
  max-width: 50%;
}

.TopicOverviewLesson-dot-image-replay {
  margin-top: -4px;
  opacity: .9;
  max-width: 50%;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.06);
  }
}

.TopicOverviewLesson-label {
  margin: 0;
  opacity: .62;
}

.TopicOverviewLesson-label-unlocked {
  margin: 0;
}


/* Activity */


.ActivityProgressBar {
  display: flex;
  margin: 0 0 16px;
}

.ActivityProgressBar-bg {
  height: 8px;
  width: 100%;
  margin: 6px 16px 6px 0;
  background: #252435;
  border-radius: 4px;
}

.ActivityProgressBar-bar {
  height: 8px;
  background: #5568FE;
  border-radius: 4px;
  transition: .15s ease-in-out;
}

.ActivityClose:hover {
  cursor: pointer
}

.ActivityCard {
  background: #252435;
  box-shadow: 0px 7px 16px rgba(4, 3, 16, 0.25);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 16px 0;
}

.ActivityCardTitle {
  display: flex;
  align-items: flex-start;
  font-weight: 700;
}

.ActivityCardTitle-icon {
  margin: 2px 8px 0 0;
}

.ActivityCardTitle-text {
  margin: 0;
  line-height: 1.5;
}

.ActivityCardTitle-span {
  font-weight: 400;
}

.ActivityCard-content {
  margin: 0 16px;
}

.ActivityCard-image-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
  position: relative;
  border-radius: 6px;
  background: #3A3A53;
  padding: 0 20px;
}

.ActivityCard-image {
  max-width: 100%;
  margin: 20px 32px;
}

.ActivityCard-badge {
  position: absolute;
  top: 4px;
  left: 4px;
}

.TitleImage {
  max-width: 100%;
  margin: 20px 0 8px;
}

.ActivityAnswers-row {
  display: flex;
  margin-right: -16px;
  flex-wrap: wrap;
}

.ActivityAnswersCard {
  background: #252435;
  box-shadow: 0px 7px 16px rgba(4, 3, 16, 0.25);
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  min-height: 48px;
}

.card-grid-spacing {
  margin: 8px;
}

.ActivityAnswersCard:hover {
  cursor: pointer;
}

.ActivityAnswersCard-selected {
  background: #41415c;
  box-shadow: 0px 11px 18px rgba(4, 3, 16, 0.5);
}

.ActivityAnswersCard-text {
  margin: 0 16px;
}

.ActivityAnswersCardImage-area {
  background: #4e5072;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  min-width: 129px;
}

.ActivityAnswersCardImage-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(9, 18, 93, 0.25);
  border-radius: 3px;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityAnswersCardImage {
  margin: 10px;
}

.Activity-instructions {
  font-weight: 200;
  margin-top: 4px;
}

.Pairs {
  display: flex;
  justify-content: space-between;
}

.Pairs-column {
  width: 48%;
}

.PairsCard {
  background: #252435;
  box-shadow: 0px 7px 16px rgba(4, 3, 16, 0.25);
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  min-height: 48px;
  border: 3px solid rgba(255, 79, 79, 0);
}

.PairsCard:hover {
  cursor: pointer;
}

.PairsCard-selected {
  background: #41415c;
  box-shadow: 0px 11px 18px rgba(4, 3, 16, 0.5);
  border: 3px solid #5568FE;
}

.PairsCard-text {
  width: 100%;
  text-align: center;
  margin: 0;
}

.PairsCard-correct {
  opacity: 1;
  animation: deactivate 1s cubic-bezier(.36,.07,.19,.97) both;
}

.PairsCard-incorrect {
  animation: shake .7s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes deactivate {

  0% {
    border: 3px solid #59B567;
    opacity: 1;
  }

  100% {
    border: 3px solid rgba(255, 79, 79, 0);
    opacity: .15;
  }
}

@keyframes shake {

  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
    border: 3px solid rgba(255, 79, 79, 1);
  }

  100% {
    border: 3px solid rgba(255, 79, 79, 0);
  }
}

.Keyboard-area {
  background: rgba(0, 0, 0, .3);
  border-radius: 8px;
  padding: 1px 0;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3), inset 4px 4px 12px rgba(0, 0, 0, 0.25);
}

.Keyboard-content {
  margin: 20px;
}

.KeyboardKey {
  height: 55px;
  background: #252435;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.35), inset 2px 2px 4px rgba(255, 255, 255, 0.10);
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  margin: 0 0 16px;
}

.KeyboardKey:hover {
  cursor: pointer;
}

.KeyboardKey-label {
  font-size: 15px;
  margin: 8px 10px;
}



/* Feedback */


.Feedback {
  width: 100%;
  background: #252435;
  position: fixed;
  bottom: -210px;
  left: 0;
  z-index: 20;
  box-shadow: 0px -7px 16px rgba(4, 3, 16, 0.25);
  display: flex;
  justify-content: center;
}

.Feedback-content {
  margin: 16px;
  max-width: 368px;
  width: 100%;
}

.Feedback-title-correct {
  color: #3dd755;
}

.Feedback-title-incorrect {
  color: #FF4572;
}

.Feedback-text {
  margin-top: 8px;
}

.Feedback-button {
  height: 54px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #14121d;
  font-weight: 600;
}

.Feedback-button:hover {
  cursor: pointer;
}

.correct {
  background: linear-gradient(180deg, #3ac64f 0%, #0f9f27 100%);
  box-shadow: 0px 17px 17px -9px rgba(62, 147, 23, 0.2), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.1);
}

.incorrect {
  background: linear-gradient(180deg, #EB4B72 0%, #C21942 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(194, 25, 66, 0.3), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.1);
  color: #fff;
}

@keyframes feedback-entrance {
  from {bottom: -210px;}
  to {bottom: 0;}
}

.Feedback-entrance {
  animation-name: feedback-entrance;
  animation-duration: .15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes feedback-exit {
  from {bottom: 0;}
  to {bottom: -210px;}
}

.Feedback-exit {
  animation-name: feedback-exit;
  animation-duration: .1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}


/* Slider */



.Frame {
  background: #252435;
  box-shadow: 0px 7px 16px rgba(4, 3, 16, 0.25);
  border-radius: 8px;
  padding: 16px 0;
  margin: 20px 0;
}

.Frame-content {
  margin: 0 20px;
  min-height: 40px;
  display: flex;
}

.Frame-selected {
  border: 1px solid #18A0FB;
  position: relative;
  margin: -1px;
}

.Frame-selected-corner-1 {
  border: 1px solid #18A0FB;
  background: #fff;
  position: absolute;
  width: 3px;
  height: 3px;
  top: -3px;
  left: -3px;
}

.Frame-selected-corner-2 {
  border: 1px solid #18A0FB;
  background: #fff;
  position: absolute;
  width: 3px;
  height: 3px;
  top: -3px;
  right: -3px;
}

.Frame-selected-corner-3 {
  border: 1px solid #18A0FB;
  background: #fff;
  position: absolute;
  width: 3px;
  height: 3px;
  bottom: -3px;
  left: -3px;
}

.Frame-selected-corner-4 {
  border: 1px solid #18A0FB;
  background: #fff;
  position: absolute;
  width: 3px;
  height: 3px;
  bottom: -3px;
  right: -3px;
}

.Frame-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.Frame-radio-container {
  display: flex;
  align-items: center;
  margin: 6px 20px 6px 0;
  transition: .1s ease-out;
}

.Frame-radio {
  border: 2px solid #C5CFD8;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
}

.Frame-radio-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Frame-radio-filled {
  background-color: #5568FE;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 50%;
}

.Frame-radio-selected {
  border: 2px solid #5568FE;
}

.Frame-radio-focus {
  box-shadow: 0 0 0 9px rgba(122, 138, 255, .35);
  background: rgba(122, 138, 255, .35);
}

.Frame-radio-label {
  margin: 0 0 0 8px;
  line-height: 1.2;
  transition: .1s ease-out;
}

.CardExample {
  margin-top: 16px;
}

/* Slider */


.Slider-container {
  display: flex;
  align-items: center;
  width: 310px;
  position: fixed;
  bottom: 90px;
  left: 50%;
  margin-left: -155px;
}

.Slider-value {
  background: #232232;
  border: 1px solid #5D5C70;
  height: 30px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 16px 8px 0;
}

.Slider {
  -webkit-appearance: none;  
  appearance: none;
  height: 8px; 
  background: #5d5c70; 
  outline: none; 
  opacity: 0.9; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 4px;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}


/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #5568fe;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0px 3px 10px rgba(12, 32, 193, 0.25); /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 3px 10px rgba(12, 32, 193, 0.25);
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #5568fe;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 0px 3px 10px rgba(12, 32, 193, 0.25);
  border: 1px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: #5568fe;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #5d5c70;
  border-radius: 4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #5d5c70;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #5d5c70;
  border-radius: 4px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #5d5c70;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #5d5c70;
}
input[type=range]::-ms-fill-upper {
  background: #5d5c70;
  border-radius: 4px;
}
input[type=range]:focus::-ms-fill-upper {
  background: #5d5c70;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Continue-button {
  height: 54px;
  border-radius: 10px;
  max-width: 368px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, #465BFF 0%, #2D44FF 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(29, 49, 206, 0.35), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.05);
}

.Continue-button-fixed {
  position: fixed;
  left: 16px;
  right: 16px;
}

.Continue-button:hover {
  cursor: pointer;
}

.btn-small {
  height: 34px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0;
}


/* Tools */



.Toolbar {
  background-color: #252435;
  height: 75px;
  position: fixed;
  bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 100%;
}

@keyframes toolbar-entrance {
  from {bottom: -80px;}
  to {bottom: 0;}
}

.Toolbar-appear {
  animation-name: toolbar-entrance;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.Toolbar-content {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.Tool {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55px;
  border-radius: 4px;
  height: 55px;
  justify-content: center;
  border: 1px solid #252435;
}



.Tool:hover {
  cursor: pointer;
}

.Tool-selected {
  background: #3B3A4F;
  border: 1px solid #515162;

}

.Tool-label {
  margin: 5px 0 0;
  font-size: 12px;
}

.SelectorTool {
  background: #252435;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  position: fixed;
  bottom: 90px;
  left: 50%;
}

.prompt {
  animation: float 1s alternate ease-in infinite;
  transform: translateY(0);
  position: fixed;
  bottom: 100px;
  left: 50vw;
  width: 200px;
  margin-left: -112px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 4px 10px rgba(85, 65, 163, .1);
  color: #444;
}

.prompt:after {
  content: "";
  position: absolute;
  top: 99%;
  left: 45%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 10px solid #fff;
  border-right: 8px solid transparent;
}

.slider-prompt {
  margin-bottom: 60px;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(6px);
  }
}


.Fill {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  width: 310px;
  position: fixed;
  bottom: 90px;
  left: 50%;
  margin-left: -155px;
}

.Fill-dark {
  background-color: #333;
}

.Fill:hover {
  cursor: pointer;
}

.Fill-row {
  display: flex;
  width: 294px;
  max-width: 415px;
  align-items: center;
  margin: 8px auto;
  height: 42px;
}


.Color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
}

.StrokeColor {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  border: 3px solid;
}


.full-radius {
  border-radius: 30px;
}

.no-color {
  background-color: none;
}

.no-color-icon {
  background-color: none;
  border: 2px solid #C4505E;
  width: 26px;
  height: 26px;
}

.white {
  background-color: #fff;
  color: #242424;
}

.white-icon {
  background-color: #fff;
  border: 2px solid #C5CFD8;
  width: 26px;
  height: 26px;
}





.DemoForm {
  margin-top: 16px;
}

.demoRadio {
  margin: 0 16px 16px 4px;
}

.demoRadio:hover {
  cursor: pointer;
}

label:hover {
  cursor: pointer;
}

input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}


/* Success */

.LessonSuccess {
  line-height: 1.5;
  font-size: 18px;
  font-weight: 300;
}

.LessonSuccess-image {
  border-radius: 4px;
}


.AuthForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #252435;
  box-shadow: 0px 8px 8px rgba(4, 3, 16, 0.15);
  border-radius: 8px;
  margin-top: 50px;
  font-size: 14px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 24px;
  box-sizing: border-box;
}

.ResetForm {
  text-align: left;
  margin-bottom: 24px;
}

.ResetMsg {
  font-size: 16px;
  opacity: .8;
  line-height: 1.4;
}

.AuthForm-content {
  width: 100%;
  max-width: 300px;
  margin: 16px;
}

.AuthForm-title {
  margin-bottom: 20px;
  width: 100%;
}

.AuthForm-error {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #FF4572;
  margin: 8px 0 0;
}

.AuthForm-error-icon {
  margin-right: 4px;
}

.AuthField {
  margin-bottom: 28px;
  width: 100%;
}

.AuthField-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  width: 100%;
}

.error-text {
  color: #FF4572;
}

.AuthField-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.AuthField-input {
  background:rgba(0, 0, 0, 0);
  border: 1px solid #B7B9CB;
  border-radius: 6px;
  width: 100%;
  height: 44px;
  font-size: 16px;
  color: #fff;
  padding: 0 16px;
  font-family: Circular, sans-serif;
  outline: none;
  box-sizing: border-box;
}

.AuthField-error {
  border: 1px solid #FF4572;
}

.AuthField-input:focus {
  border: 1px solid #5568FE;
  outline: solid #5568FE;
}

.AuthField-pw-icon {
  margin-left: -30px;
}

.AuthField-reset-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

button {
  border: none;
}

.AuthButton {
  height: 54px;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px auto 0;
  color: #fff;
  font-family: Circular, sans-serif;
  font-weight: 500;
  background: linear-gradient(180deg, #465BFF 0%, #2D44FF 100%);
  box-shadow: 0px 17.5px 17.5px -8.75px rgba(29, 49, 206, 0.3), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.05);
}

.AuthButton:hover {
  cursor: pointer;
}

.Auth-link-message {
  margin: 28px 0 0;
}

.Auth-link-message2 {
  margin: 16px 0 0;
}

.Auth-link {
  color: #9FAAFF;
}

.Auth-link:hover {
  cursor: pointer;
}

.Nav {
  width: 100%;
  max-width: 1080px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  margin: 8px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.logo-row {
  width: 100%;
  margin: 28px 0;
  display: flex;
  justify-content: center;
}

.Nav-logo {
  width: 80px;
}

.Nav-logo:hover {
  cursor: pointer
}

.Nav-actions {
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 160px;
  margin: 8px 0 0;
  justify-content: space-between;
}

.Nav-actions:hover {
  cursor: pointer;
}

.Nav-logout {
  display: flex;
  align-items: center;
  color: #B7B9CB;
  font-size: 15px;
}

.Nav-logout:hover {
  cursor: pointer;
}

.Nav-logout-icon {
  margin-right: 6px;
}








.overline {
  font-size: 15px;
  color: #999bad;
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
}

.mt-30 {
  margin-top: 30px;
}

img {
  max-width: 100%;
}

.flex {
  display: flex;
}

.level-purple {
  background-color: #5568fe;
}

.level-green {
  background-color: #59B567;
}

.level-yellow {
  background-color: #ebde6D;
}

.level-orange {
  background-color: #ea9e59;
}

.t-12 {
  top: 12px;
}

.t-12 {
  top: 72px;
}

.absolute {
  position: absolute;
}

.selected {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background-color: #E9ECF1;
  display: flex;
  align-items: center;
  justify-content: center;
}